import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				SparkleClean
			</title>
			<meta name={"description"} content={"Ваш дім, наш пріоритет"} />
			<meta property={"og:title"} content={"SparkleClean"} />
			<meta property={"og:description"} content={"Ваш дім, наш пріоритет"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/665448d7e8900d0022f2aff2/images/1-3.jpg?v=2024-05-27T08:50:53.460Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/665448d7e8900d0022f2aff2/images/1-3.jpg?v=2024-05-27T08:50:53.460Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/665448d7e8900d0022f2aff2/images/1-3.jpg?v=2024-05-27T08:50:53.460Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/665448d7e8900d0022f2aff2/images/1-3.jpg?v=2024-05-27T08:50:53.460Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/665448d7e8900d0022f2aff2/images/1-3.jpg?v=2024-05-27T08:50:53.460Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/665448d7e8900d0022f2aff2/images/1-3.jpg?v=2024-05-27T08:50:53.460Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/665448d7e8900d0022f2aff2/images/1-3.jpg?v=2024-05-27T08:50:53.460Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="link3" />
		</Components.Header>
		<Section padding="90px 0 100px 0" quarkly-title="Price-19">
			<Text margin="0px 0px 20px 0px" font="--headline2" text-align="center">
				Експертні рішення з клінінгу
			</Text>
			<Text
				margin="0px 0px 70px 0px"
				text-align="center"
				font="--lead"
				color="--darkL1"
				md-margin="0px 0px 50px 0px"
			>
				У SparkleClean Services ми пропонуємо різноманітні рішення для чищення, адаптовані до ваших потреб. Наша місія полягає в тому, щоб ваш дім був бездоганним і комфортним, використовуючи екологічно чисті продукти та передові технології.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="0 50px"
				md-grid-template-columns="1fr"
				md-grid-gap="36px 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					justify-content="flex-start"
				>
					<Text margin="0px 0px 20px 0px" font="--headline3">
						Прибирання житла
					</Text>
					<Box min-width="100px" min-height="100px">
						<Box
							min-width="10px"
							min-height="10px"
							display="flex"
							justify-content="space-between"
							align-items="center"
							margin="0px 0px 10px 0px"
							font="--base"
						>
							<Text margin="0px 10px 0px 0px" font="--base" color="--darkL1">
								Стандартне прибирання: регулярний догляд за житловими приміщеннями, кухнями та ванними кімнатами.
							</Text>
						</Box>
						<Box
							min-width="10px"
							min-height="10px"
							display="flex"
							justify-content="space-between"
							align-items="center"
							margin="0px 0px 10px 0px"
						>
							<Text margin="0px 10px 0px 0px" font="--base" color="--darkL1">
								Житлові зони: прибирання пилу, прибирання пилососом і миття підлоги.
							</Text>
						</Box>
						<Text margin="0px 10px 0px 0px" font="--base" color="--darkL1">
							Кухня: Протирання стільниць, прибирання техніки.
						</Text>
						<Text margin="0px 10px 0px 0px" font="--base" color="--darkL1">
							Глибоке очищення: Досягаючи прихованих ділянок для ретельного очищення.
						</Text>
						<Box
							min-width="10px"
							min-height="10px"
							display="flex"
							justify-content="space-between"
							align-items="center"
							color="--darkL1"
						>
							<Text margin="0px 10px 0px 0px" font="--base" color="--darkL1">
								Ванні кімнати: миття унітазів, раковин, душів.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					justify-content="flex-start"
				>
					<Text margin="0px 0px 20px 0px" font="--headline3">
						Прибирання при заїзді/виїзді
					</Text>
					<Box min-width="100px" min-height="100px">
						<Box
							min-width="10px"
							min-height="10px"
							display="flex"
							justify-content="space-between"
							align-items="center"
							margin="0px 0px 10px 0px"
							font="--base"
						>
							<Text margin="0px 10px 0px 0px" font="--base" color="--darkL1">
								Прибирання перед переїздом: підготовка вашого нового будинку.
							</Text>
						</Box>
						<Box
							min-width="10px"
							min-height="10px"
							display="flex"
							justify-content="space-between"
							align-items="center"
							margin="0px 0px 10px 0px"
						>
							<Text margin="0px 10px 0px 0px" font="--base" color="--darkL1">
								Підлоги: Глибоке чищення килимів, плитки.
							</Text>
						</Box>
						<Text margin="0px 10px 0px 0px" font="--base" color="--darkL1">
							Шафи: протирання всередині та зовні.
						</Text>
						<Text margin="0px 10px 0px 0px" font="--base" color="--darkL1">
							Прибирання ванної кімнати: Ретельна санітарна обробка.
						</Text>
						<Text margin="0px 10px 0px 0px" font="--base" color="--darkL1">
							Вікна: Очищення порогів, колій.
						</Text>
						<Box
							min-width="10px"
							min-height="10px"
							display="flex"
							justify-content="space-between"
							align-items="center"
							color="--darkL1"
						>
							<Text margin="0px 10px 0px 0px" font="--base" color="--darkL1">
								Прибирання після переїзду: ваш старий дім залишиться чистим.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					justify-content="flex-start"
				>
					<Text margin="0px 0px 20px 0px" font="--headline3">
						Сезонне прибирання
					</Text>
					<Box min-width="100px" min-height="100px">
						<Box
							min-width="10px"
							min-height="10px"
							display="flex"
							justify-content="space-between"
							align-items="center"
							margin="0px 0px 10px 0px"
							font="--base"
						>
							<Text margin="0px 10px 0px 0px" font="--base" color="--darkL1">
								Весняне прибирання: освіження після зими.
							</Text>
						</Box>
						<Box
							min-width="10px"
							min-height="10px"
							display="flex"
							justify-content="space-between"
							align-items="center"
							margin="0px 0px 10px 0px"
						>
							<Text margin="0px 10px 0px 0px" font="--base" color="--darkL1">
								Відкритий майданчик: прибирання внутрішніх двориків.
							</Text>
						</Box>
						<Text margin="0px 10px 0px 0px" font="--base" color="--darkL1">
							Деталізація інтер’єру: Глибоке прибирання кімнат.
						</Text>
						<Text margin="0px 10px 0px 0px" font="--base" color="--darkL1">
							Декорації: налаштування декорацій.
						</Text>
						<Text margin="0px 10px 0px 0px" font="--base" color="--darkL1">
							Організація: розчищення простору.
						</Text>
						<Box
							min-width="10px"
							min-height="10px"
							display="flex"
							justify-content="space-between"
							align-items="center"
							color="--darkL1"
						>
							<Text margin="0px 10px 0px 0px" font="--base" color="--darkL1">
								Святкове прибирання: Підготовка до свят.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" background="#f0e5dc" quarkly-title="Product-4">
			<Override slot="SectionContent" align-items="center" />
			<Box
				min-width="100px"
				min-height="100px"
				display="block"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				margin="0px 0px 0 0px"
				lg-margin="0px 0px 0 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					align-items="center"
					md-flex-direction="column"
				>
					<Image
						src="https://uploads.quarkly.io/665448d7e8900d0022f2aff2/images/1-3.jpg?v=2024-05-27T08:50:53.460Z"
						display="block"
						max-width="100%"
						margin="0px 0px 0 0px"
						height="500px"
						width="50%"
						object-fit="contain"
						sm-height="220px"
						md-width="100%"
						md-margin="0px 0px 50px 0px"
						sm-margin="0px 0px 35px 0px"
						order="1"
						srcSet="https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/1-3.jpg?v=2024-05-27T08%3A50%3A53.460Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/1-3.jpg?v=2024-05-27T08%3A50%3A53.460Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/1-3.jpg?v=2024-05-27T08%3A50%3A53.460Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/1-3.jpg?v=2024-05-27T08%3A50%3A53.460Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/1-3.jpg?v=2024-05-27T08%3A50%3A53.460Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/1-3.jpg?v=2024-05-27T08%3A50%3A53.460Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/1-3.jpg?v=2024-05-27T08%3A50%3A53.460Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="0px 80px 0px 80px"
						width="50%"
						lg-padding="0px 50px 0px 50px"
						md-width="100%"
						sm-padding="0px 0 0px 0"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 42px/1.2 --fontFamily-sans" text-align="left" lg-font="normal 600 36px/1.2 --fontFamily-sans">
							Спеціалізоване прибирання
						</Text>
						<Text margin="0px 0px 50px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" lg-margin="0px 0px 40px 0px" sm-margin="0px 0px 30px 0px">
							Ми задовольняємо унікальні потреби прибирання за допомогою спеціалізованих послуг.
							<br />
							<br />
							<br />
							Очищення від алергії: зменшення домашнього алергену.
    Пилосос HEPA: збирання пилу.
    Повітряні фільтри: очищення, заміна фільтрів.
    Постільна білизна: матраци глибокого очищення.
Прибирання після будівництва: прибирання після ремонту.
    Видалення сміття: очищення від пилу.
    Детальне очищення: очищення рам, кріплень.
    Догляд за підлогою: Полірування підлоги.
						</Text>
						<Button
							font="normal 500 18px/1.5 --fontFamily-sans"
							href="/"
							padding="0 0 0 0"
							background="0"
							color="--red"
						>
							Контакти &gt;&gt;
						</Button>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="100px 0 120px 0" sm-padding="80px 0 90px 0" quarkly-title="Statistics-10">
			<Text
				margin="0px 0px 20px 0px"
				letter-spacing="2px"
				color="#cd9f55"
				font="--headline2"
				border-color="#dca654"
			>
				Зв'яжіться з нами
			</Text>
			<Text margin="0px 0px 50px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" lg-margin="0px 0px 40px 0px" sm-margin="0px 0px 30px 0px">
				Відчуйте відмінності SparkleClean і повний спектр пропозицій. Ми прагнемо надавати виняткові послуги. Щоб отримати запит або запланувати послугу, зв’яжіться з нами. Дозвольте нам зайнятися прибиранням, а ви насолоджуватиметеся бездоганним домом.
			</Text>
		</Section>
		<Components.Footer>
			<Override slot="link4" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65b35f058e2e8e002176cca3"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});